import React from "react";
import { useNgSetting } from "./useNgSetting";
import { constantNoMesin } from "../../util/constant";
import { constCustomer } from "../../util/constant";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import CloseLogo from "../../Assets/close_icon.png";
import "./ngSetting.css";

const NgSetting = () => {
  const {
    dataUser,
    subMenuLaporanBahanBaku,
    setSubMenuLaporanBahanBaku,
    companyPick,
    setCompanyPick,
    mesin,
    setMesin,
    dateLaporan,
    setDateLaporan,
    errMsg,
    popUpErr,
    handlePopUp,
    jumlahReject,
    setJumlahReject,
    handleKirimReject,
    isErrorProduksi,
    dataRejectSetting,
    tipeBahan,
    setTipeBahan,
    shift,
    setShift,
    tipeNg,
    setTipeNg,
    jenisSetting,
    setJenisSetting,
    dataListProduk,
    produkReject,
    setProdukReject,
    listProduk,
    filterShift,
    setFilterShift,
    kepalaShift,
    setKepalaShift,
    jenisNGLain,
    setJenisNGLain,
    isPopUp,
    setIsPopUp,
    dateExport,
    setDateExport,
    dateUntil,
    setDateUntil,
    exportProduksi,
  } = useNgSetting();

  const sortDataRejectSetting =
    dataRejectSetting &&
    dataRejectSetting.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_inventory">
      {popUpErr ? (
        <PopUpComponent>
          <p>{errMsg}</p>
          <button
            className="button_submit_memo"
            style={{ margin: "0px" }}
            onClick={() => handlePopUp()}
          >
            OK
          </button>
        </PopUpComponent>
      ) : (
        ""
      )}
      {isPopUp ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Laporan Produksi</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setIsPopUp(false)}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Dari</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateExport}
                onChange={(e) => setDateExport(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Sampai</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateUntil}
                onChange={(e) => setDateUntil(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            <button
              className="button_popup background_red"
              onClick={() => exportProduksi()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">LAPORAN NG SETTING</p>
      <div className="wrapper_sub_menu_history">
        {dataUser.role === "produksi_leader" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuLaporanBahanBaku === "Produksi"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuLaporanBahanBaku("Produksi")}
          >
            Produksi
          </p>
        ) : (
          ""
        )}
        <p
          className={
            subMenuLaporanBahanBaku === "RiwayatProduksi"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuLaporanBahanBaku("RiwayatProduksi")}
        >
          Riwayat Produksi
        </p>
      </div>
      {subMenuLaporanBahanBaku === "Produksi" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {isErrorProduksi ? (
            <p style={{ color: "red", margin: "0px" }}>
              Mohon Mengisi Semua Kolom Berbintang merah!
            </p>
          ) : (
            ""
          )}
          <div style={{ width: "40%" }}>
            <p>
              Pilih Tanggal<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="date"
              value={dateLaporan}
              style={{ height: "28px", width: "97%" }}
              onChange={(e) => setDateLaporan(e.target.value)}
            />
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Mesin<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={mesin}
              onChange={(e) => setMesin(e.target.value)}
            >
              <option selected disabled>
                Pilih Mesin
              </option>
              {constantNoMesin &&
                constantNoMesin.map((data) => (
                  <option value={data}>{data}</option>
                ))}
            </select>
          </div>
          <div style={{ width: "40%", marginRight: "12px" }}>
            <p>
              Pilih Customer<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={companyPick}
              onChange={(e) => setCompanyPick(e.target.value)}
            >
              <option selected disabled>
                Pilih Customer
              </option>
              {constCustomer &&
                constCustomer.map((data) => (
                  <option value={data}>{data}</option>
                ))}
            </select>
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Produk<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={produkReject}
              onChange={(e) => setProdukReject(e.target.value)}
            >
              <option selected disabled>
                Pilih Produk
              </option>
              {dataListProduk &&
                dataListProduk.data.map((data, index) => (
                  <option value={index}>
                    {data.name} - {data.color}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ width: "40%", marginRight: "12px" }}>
            <p>
              Pilih Tipe Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={tipeBahan}
              onChange={(e) => setTipeBahan(e.target.value)}
            >
              <option selected disabled>
                Pilih Tipe Bahan
              </option>
              <option value={"Crusher"}>Crusher</option>
              <option value={"Murni"}>Murni</option>
            </select>
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Shift<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={shift}
              onChange={(e) => setShift(e.target.value)}
            >
              <option selected disabled>
                Pilih Shift
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div style={{ width: "40%", marginRight: "12px" }}>
            <p>
              Pilih Kepala Shift<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={kepalaShift}
              onChange={(e) => setKepalaShift(e.target.value)}
            >
              <option selected disabled>
                Pilih Kepala Shift
              </option>
              <option value="Supriadi">Supriadi</option>
              <option value="Achmad S">Achmad S</option>
              <option value="Saripudin">Saripudin</option>
            </select>
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Jenis NG<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={tipeNg}
              onChange={(e) => setTipeNg(e.target.value)}
            >
              <option selected disabled>
                Pilih Tipe NG
              </option>
              <option value="Flash">Flash</option>
              <option value="Gelombang">Gelombang</option>
              <option value="Ejector Mark">Ejector Mark</option>
              <option value="Ex Minyak">Ex Minyak</option>
              <option value="Belang">Belang</option>
              <option value="Unfill">Unfill</option>
              <option value="Tanpa Timah">Tanpa Timah</option>
              <option value="Black Spot">Black Spot</option>
              <option value="Lain - Lain">Lain - Lain</option>
            </select>
          </div>
          {tipeNg === "Lain - Lain" ? (
            <div style={{ width: "40%", marginRight: "12px" }}>
              <p>Lain - Lain</p>
              <input
                placeholder="Jenis NG Lain - Lain"
                style={{ maxWidth: "95%", height: "32px" }}
                value={jenisNGLain}
                type="text"
                onChange={(e) => setJenisNGLain(e.target.value)}
              />
            </div>
          ) : (
            ""
          )}
          <div
            style={
              tipeNg === "Lain - Lain"
                ? { width: "50%", marginRight: "12px" }
                : { width: "40%", marginRight: "12px" }
            }
          >
            <p>
              Pilih Jenis Setting<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={jenisSetting}
              onChange={(e) => setJenisSetting(e.target.value)}
            >
              <option selected disabled>
                Pilih Jenis Setting
              </option>
              <option value="Setting Berjalan">Setting Berjalan</option>
              <option value="Ganti Mold">Ganti Mold</option>
              <option value="Ganti Warna">Ganti Warna</option>
              <option value="Ganti Mold & Warna">Ganti Mold & Warna</option>
            </select>
          </div>
          <div style={{ width: "53%" }}>
            <p>
              Jumlah Reject<label style={{ color: "red" }}>*</label>
            </p>
            <input
              placeholder="Jumlah Reject (Pcs)"
              type="number"
              style={{ maxWidth: "95%", height: "32px" }}
              value={jumlahReject}
              onChange={(e) => setJumlahReject(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ width: "100%" }}>
            <button
              className="button_submit_memo"
              onClick={() => handleKirimReject()}
            >
              Kirim
            </button>
          </div>
        </div>
      ) : subMenuLaporanBahanBaku === "RiwayatProduksi" ? (
        <>
          <div style={{ display: "flex", alignItems: "end" }}>
            <div style={{ marginRight: "24px" }}>
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                value={dateLaporan}
                style={{ height: "28px" }}
                className="width_date_laporan"
                onChange={(e) => setDateLaporan(e.target.value)}
              />
            </div>
            <div>
              <p>
                Pilih Shift<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "100%" }}
                value={filterShift}
                onChange={(e) => setFilterShift(e.target.value)}
              >
                <option selected disabled>
                  Pilih Shift
                </option>
                <option value="Supriadi">Supriadi</option>
                <option value="Achmad S">Achmad S</option>
                <option value="Saripudin">Saripudin</option>
              </select>
            </div>
            {dataUser.role === "produksi_admin" ||
            dataUser.role === "super_user" ? (
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    background: "green",
                    color: "white",
                    padding: "12px",
                    borderRadius: "6px",
                    margin: "0",
                    cursor: "pointer",
                    marginLeft: "24px",
                  }}
                  onClick={() => setIsPopUp(true)}
                >
                  Export
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <br />

          <div className="table_laporan_bahan_baku">
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    padding: "12px 6px",
                    width: "6%",
                  }}
                >
                  MC
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Produk
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Warna
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Tipe NG
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Jumlah (Pcs)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Setting
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Pembuat
                </th>
              </tr>

              {sortDataRejectSetting &&
                sortDataRejectSetting.map((ele) => {
                  let firstFind =
                    listProduk &&
                    listProduk.find((o) => o.company === ele.customer);
                  let secondFind =
                    firstFind && firstFind.data[ele.produkReject];

                  const splitNama = ele.creator
                    .split(" ")
                    .slice(0, 1)
                    .join(" ");

                  return (
                    <tr>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.mesin}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {secondFind && secondFind.name}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {secondFind && secondFind.color}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.tipeNg}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.jumlah}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.jenisSetting}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {splitNama}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default NgSetting;
