import React from "react";
import "./InputBarangJadi.css";
import { useInputBarangJadi } from "./useInputBarangJadi";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import Select from "react-select";

const InputBarangJadi = () => {
  const {
    popUpSubmit,
    popUpErrorSubmit,
    setCompanyPickInputGbj,
    setJumlahInputGbj,
    setKeteranganGbj,
    jumlahInputGbj,
    keteranganGbj,
    companyPickInputGbj,
    dataListBarangJadi,
    reFormat,
    setProdukPick,
    produkPick,
    handleKirimInputGbj,
    setPopUpSubmit,
    setPopUpErrorSubmit,
  } = useInputBarangJadi();

  return (
    <div className="container_dashboard">
      {popUpSubmit ? (
        <PopUpComponent>
          <p>Terima Kasih! Anda Telah Men-update Stock Masuk Bahan.</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpErrorSubmit ? (
        <PopUpComponent>
          <p style={{ color: "red" }}>
            Mohon pastikan semua kolom yang Berbintang Merah Terisi !
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpErrorSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">INPUT GUDANG BARANG JADI</p>
      <div className="flex_wrap_request">
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Perusahaan<label style={{ color: "red" }}>*</label>
          </p>
          <select
            onChange={(e) => setCompanyPickInputGbj(e.target.value)}
            className="input_select"
            defaultValue="Pilih Nama Perusahaan"
          >
            <option selected={companyPickInputGbj === ""} disabled>
              Pilih Nama Perusahaan
            </option>
            {dataListBarangJadi &&
              dataListBarangJadi.map((data, i) => (
                <option
                  value={data.company}
                  key={i}
                  selected={companyPickInputGbj === data.company}
                >
                  {data.company}
                </option>
              ))}
          </select>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Nama Barang<label style={{ color: "red" }}>*</label>
          </p>
          <div className="select_item_gbj">
            <Select
              options={reFormat}
              value={produkPick || ""}
              onChange={setProdukPick}
            />
          </div>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Jumlah (Pcs)<label style={{ color: "red" }}>*</label>
          </p>
          <input
            type="number"
            className="input_jumlah"
            placeholder="Masukkan Jumlah (Pcs)"
            value={jumlahInputGbj}
            onChange={(e) => setJumlahInputGbj(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div style={{ display: "flex" }} className="column_mobile">
          <div className="wrapper_input">
            <p className="font_weight_bold">Keterangan</p>
            <textarea
              className="text_area"
              placeholder="Masukkan Keterangan Jika Diperlukan"
              onChange={(e) => setKeteranganGbj(e.target.value)}
              value={keteranganGbj}
            />
          </div>
          <div className="wrapper_button_kirim_request_in">
            <div
              className="button_kirim_request_in"
              onClick={() => handleKirimInputGbj()}
            >
              Kirim
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputBarangJadi;
