import React from "react";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import { useStockIn } from "./useStockIn";
import "./StockIn.css";
const StockIn = () => {
  const {
    dataUser,
    companyRequest,
    setCompanyRequest,
    setMaterialName,
    handleSubmitRequest,
    dataBahan,
    popUpRequest,
    jumlahRequest,
    setJumlahRequest,
    setKeteranganRequestStockIn,
    dataListBahan,
    handleKirimRequestIn,
    materialName,
    popUpErrorRequest,
    keteranganRequestStockIn,
    statusBahan,
    setStatusBahan,
    subMenuStockIn,
    setSubMenuStockIn,
    dataCrusher,
    dataLogBahanCrusher,
    filterDate,
    setFilterDate,
    dataPewarna,
  } = useStockIn();

  return (
    <div className="container_dashboard">
      {popUpRequest ? (
        <PopUpComponent>
          <p>Terima Kasih! Anda Telah Men-update Stock Masuk Bahan.</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleSubmitRequest("OK")}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpErrorRequest ? (
        <PopUpComponent>
          <p>Mohon pastikan semua kolom yang Berbintang Merah Terisi !</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleSubmitRequest("OK")}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">
        {subMenuStockIn === "BahanBaku"
          ? "INPUT BAHAN"
          : subMenuStockIn === "Crusher"
          ? "INPUT CRUSHER"
          : subMenuStockIn === "RiwayatBahanBaku"
          ? "RIWAYAT BAHAN BAKU"
          : subMenuStockIn === "RiwayatCrusher"
          ? "RIWAYAT CRUSHER"
          : subMenuStockIn === "Pewarna"
          ? "INPUT PEWARNA"
          : subMenuStockIn === "RiwayatPewarna"
          ? "RIWAYAT PEWARNA"
          : ""}
      </p>
      <div className="wrapper_sub_menu_history">
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuStockIn === "BahanBaku"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuStockIn("BahanBaku")}
          >
            Bahan Baku
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing" ||
        dataUser.role === "warehouse_crusher" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuStockIn === "Crusher"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuStockIn("Crusher")}
          >
            Crusher
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuStockIn === "Pewarna"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuStockIn("Pewarna")}
          >
            Pewarna
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuStockIn === "RiwayatBahanBaku"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuStockIn("RiwayatBahanBaku")}
          >
            Riwayat Bahan Baku
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing" ||
        dataUser.role === "warehouse_crusher" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuStockIn === "RiwayatCrusher"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuStockIn("RiwayatCrusher")}
          >
            Riwayat Crusher
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuStockIn === "RiwayatPewarna"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuStockIn("RiwayatPewarna")}
          >
            Riwayat Pewarna
          </p>
        ) : (
          ""
        )}
      </div>
      {subMenuStockIn === "BahanBaku" &&
      (dataUser.role === "super_user" ||
        dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_mixing") ? (
        <div className="flex_wrap_request">
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Keluar / Masuk Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setStatusBahan("Keluar")}
                className="wrapper_radio"
                style={{ marginRight: "24px" }}
              >
                <input
                  type="radio"
                  value="Keluar"
                  checked={statusBahan === "Keluar"}
                />
                <label style={{ fontSize: "14px" }}>Keluar</label>
              </div>
              <div
                onClick={() => setStatusBahan("Masuk")}
                className="wrapper_radio"
              >
                <input
                  type="radio"
                  value="Masuk"
                  checked={statusBahan === "Masuk"}
                />
                <label style={{ fontSize: "14px" }}>Masuk</label>
              </div>
            </div>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Perusahaan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              onChange={(e) => setCompanyRequest(e.target.value)}
              className="input_select"
              defaultValue="Pilih Nama Perusahaan"
            >
              <option selected={companyRequest === ""} disabled>
                Pilih Nama Perusahaan
              </option>
              {dataListBahan &&
                dataListBahan.map((data, i) => (
                  <option
                    value={data.company}
                    key={i}
                    selected={companyRequest === data.company}
                  >
                    {data.company}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Nama Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              className="input_select"
              onChange={(e) => setMaterialName(e.target.value)}
              defaultValue="Pilih Nama Bahan"
            >
              <option selected={materialName === ""} disabled>
                Pilih Nama Bahan
              </option>
              {dataBahan &&
                dataBahan.map((data, i) => (
                  <option
                    value={data.material}
                    key={i}
                    selected={materialName === data.material}
                  >
                    {data.material}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Jumlah (Kg)<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="number"
              className="input_jumlah"
              placeholder="Masukkan Jumlah (Kg)"
              value={jumlahRequest}
              onChange={(e) => setJumlahRequest(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ display: "flex" }} className="column_mobile">
            <div className="wrapper_input">
              <p className="font_weight_bold">Keterangan</p>
              <textarea
                className="text_area"
                placeholder="Masukkan Keterangan Jika Diperlukan"
                onChange={(e) => setKeteranganRequestStockIn(e.target.value)}
                value={keteranganRequestStockIn}
              />
            </div>
            <div className="wrapper_button_kirim_request_in">
              <div
                className="button_kirim_request_in"
                onClick={() => handleKirimRequestIn()}
              >
                Kirim
              </div>
            </div>
          </div>
        </div>
      ) : subMenuStockIn === "Crusher" &&
        (dataUser.role === "super_user" ||
          dataUser.role === "warehouse_admin" ||
          dataUser.role === "warehouse_crusher" ||
          dataUser.role === "warehouse_mixing") ? (
        <div className="flex_wrap_request">
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Keluar / Masuk Crusher<label style={{ color: "red" }}>*</label>
            </p>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setStatusBahan("Keluar")}
                className="wrapper_radio"
                style={{ marginRight: "24px" }}
              >
                <input
                  type="radio"
                  value="Keluar"
                  checked={statusBahan === "Keluar"}
                />
                <label style={{ fontSize: "14px" }}>Keluar</label>
              </div>
              <div
                onClick={() => setStatusBahan("Masuk")}
                className="wrapper_radio"
              >
                <input
                  type="radio"
                  value="Masuk"
                  checked={statusBahan === "Masuk"}
                />
                <label style={{ fontSize: "14px" }}>Masuk</label>
              </div>
            </div>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Perusahaan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              onChange={(e) => setCompanyRequest(e.target.value)}
              className="input_select"
              defaultValue="Pilih Nama Perusahaan"
            >
              <option selected={companyRequest === ""} disabled>
                Pilih Nama Perusahaan
              </option>
              {dataListBahan &&
                dataListBahan.map((data, i) => (
                  <option
                    value={data.company}
                    key={i}
                    selected={companyRequest === data.company}
                  >
                    {data.company}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Nama Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              className="input_select"
              onChange={(e) => setMaterialName(e.target.value)}
              defaultValue="Pilih Nama Bahan"
            >
              <option selected={materialName === ""} disabled>
                Pilih Crusher
              </option>
              {dataCrusher &&
                dataCrusher.map((data, i) => (
                  <option
                    value={data.material}
                    key={i}
                    selected={materialName === data.material}
                  >
                    {data.material}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Jumlah (Kg)<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="number"
              className="input_jumlah"
              placeholder="Masukkan Jumlah (Kg)"
              value={jumlahRequest}
              onChange={(e) => setJumlahRequest(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ display: "flex" }} className="column_mobile">
            <div className="wrapper_input">
              <p className="font_weight_bold">Keterangan</p>
              <textarea
                className="text_area"
                placeholder="Masukkan Keterangan Jika Diperlukan"
                onChange={(e) => setKeteranganRequestStockIn(e.target.value)}
                value={keteranganRequestStockIn}
              />
            </div>
            <div className="wrapper_button_kirim_request_in">
              <div
                className="button_kirim_request_in"
                onClick={() => handleKirimRequestIn()}
              >
                Kirim
              </div>
            </div>
          </div>
        </div>
      ) : subMenuStockIn === "Pewarna" &&
        (dataUser.role === "super_user" ||
          dataUser.role === "warehouse_admin" ||
          dataUser.role === "warehouse_mixing") ? (
        <div className="flex_wrap_request">
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Keluar / Masuk Crusher<label style={{ color: "red" }}>*</label>
            </p>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setStatusBahan("Keluar")}
                className="wrapper_radio"
                style={{ marginRight: "24px" }}
              >
                <input
                  type="radio"
                  value="Keluar"
                  checked={statusBahan === "Keluar"}
                />
                <label style={{ fontSize: "14px" }}>Keluar</label>
              </div>
              <div
                onClick={() => setStatusBahan("Masuk")}
                className="wrapper_radio"
              >
                <input
                  type="radio"
                  value="Masuk"
                  checked={statusBahan === "Masuk"}
                />
                <label style={{ fontSize: "14px" }}>Masuk</label>
              </div>
            </div>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Perusahaan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              onChange={(e) => setCompanyRequest(e.target.value)}
              className="input_select"
              defaultValue="Pilih Nama Perusahaan"
            >
              <option selected={companyRequest === ""} disabled>
                Pilih Nama Perusahaan
              </option>
              {dataListBahan &&
                dataListBahan.map((data, i) => (
                  <option
                    value={data.company}
                    key={i}
                    selected={companyRequest === data.company}
                  >
                    {data.company}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Nama Pewarna<label style={{ color: "red" }}>*</label>
            </p>
            <select
              className="input_select"
              onChange={(e) => setMaterialName(e.target.value)}
              defaultValue="Pilih Nama Bahan"
            >
              <option selected={materialName === ""} disabled>
                Pilih Pewarna
              </option>
              {dataPewarna &&
                dataPewarna.map((data, i) => (
                  <option
                    value={data.material}
                    key={i}
                    selected={materialName === data.material}
                  >
                    {data.material}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Jumlah (Kg)<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="number"
              className="input_jumlah"
              placeholder="Masukkan Jumlah (Kg)"
              value={jumlahRequest}
              onChange={(e) => setJumlahRequest(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ display: "flex" }} className="column_mobile">
            <div className="wrapper_input">
              <p className="font_weight_bold">Keterangan</p>
              <textarea
                className="text_area"
                placeholder="Masukkan Keterangan Jika Diperlukan"
                onChange={(e) => setKeteranganRequestStockIn(e.target.value)}
                value={keteranganRequestStockIn}
              />
            </div>
            <div className="wrapper_button_kirim_request_in">
              <div
                className="button_kirim_request_in"
                onClick={() => handleKirimRequestIn()}
              >
                Kirim
              </div>
            </div>
          </div>
        </div>
      ) : subMenuStockIn === "RiwayatBahanBaku" &&
        (dataUser.role === "super_user" ||
          dataUser.role === "warehouse_admin" ||
          dataUser.role === "warehouse_mixing") ? (
        <div
          className="table_inventory_mobile width-table-karantina"
          style={{ position: "absolute", zIndex: "-1" }}
        >
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  padding: "12px 6px",
                  width: "6%",
                  borderRight: "1px solid lightgray",
                }}
              >
                No
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Customer
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Material
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Jumlah
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Status
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Operator
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Keterangan
              </th>
            </tr>
            {dataLogBahanCrusher &&
              dataLogBahanCrusher.bahan.map((e, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{
                        padding: "12px 6px",
                        lineHeight: "18px",
                      }}
                    >
                      {index + 1}
                    </td>

                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.company}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.material}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.dataWeight}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={
                        e && e.statusBahan === "Masuk"
                          ? { padding: "12px 6px", color: "green" }
                          : { padding: "12px 6px", color: "red" }
                      }
                    >
                      {e && e.statusBahan}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.updatedBy.split(" ").slice(0, 1).join(" ")}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.keterangan}
                    </td>
                  </tr>
                );
              })}
          </table>
          <br />
        </div>
      ) : subMenuStockIn === "RiwayatCrusher" &&
        (dataUser.role === "super_user" ||
          dataUser.role === "warehouse_admin" ||
          dataUser.role === "warehouse_crusher" ||
          dataUser.role === "warehouse_mixing") ? (
        <div
          className="table_inventory_mobile width-table-karantina"
          style={{ position: "absolute", zIndex: "-1" }}
        >
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  padding: "12px 6px",
                  width: "6%",
                  borderRight: "1px solid lightgray",
                }}
              >
                No
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Customer
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Material
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Jumlah
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Status
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Operator
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Keterangan
              </th>
            </tr>
            {dataLogBahanCrusher &&
              dataLogBahanCrusher.crusher.map((e, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{
                        padding: "12px 6px",
                        lineHeight: "18px",
                      }}
                    >
                      {index + 1}
                    </td>

                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.company}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.material}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.dataWeight}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={
                        e && e.statusBahan === "Masuk"
                          ? { padding: "12px 6px", color: "green" }
                          : { padding: "12px 6px", color: "red" }
                      }
                    >
                      {e && e.statusBahan}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.updatedBy.split(" ").slice(0, 1).join(" ")}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.keterangan}
                    </td>
                  </tr>
                );
              })}
          </table>
          <br />
        </div>
      ) : subMenuStockIn === "RiwayatPewarna" &&
        (dataUser.role === "super_user" ||
          dataUser.role === "warehouse_admin" ||
          dataUser.role === "warehouse_mixing") ? (
        <div
          className="table_inventory_mobile width-table-karantina"
          style={{ position: "absolute", zIndex: "-1" }}
        >
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  padding: "12px 6px",
                  width: "6%",
                  borderRight: "1px solid lightgray",
                }}
              >
                No
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Customer
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Material
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Jumlah
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Status
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Operator
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Keterangan
              </th>
            </tr>
            {dataLogBahanCrusher &&
              dataLogBahanCrusher.pewarna.map((e, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{
                        padding: "12px 6px",
                        lineHeight: "18px",
                      }}
                    >
                      {index + 1}
                    </td>

                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.company}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.material}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.dataWeight}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={
                        e && e.statusBahan === "Masuk"
                          ? { padding: "12px 6px", color: "green" }
                          : { padding: "12px 6px", color: "red" }
                      }
                    >
                      {e && e.statusBahan}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.updatedBy.split(" ").slice(0, 1).join(" ")}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.keterangan}
                    </td>
                  </tr>
                );
              })}
          </table>
          <br />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default StockIn;
