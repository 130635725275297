import React from "react";
import { constantNoMesin } from "../../util/constant";
import { useOperator } from "./useOperator";
import PopUpComponent from "../../components/PopUp/PopUpComponent";

const Operator = () => {
  const {
    handleRejectDesc,
    dataReject,
    addFields,
    removeFields,
    shiftOperator,
    setShiftOperator,
    setMesinOperator,
    setDateOperator,
    handleKirim,
    setIsiPerKemasan,
    setAktualCavity,
    setCounterAkhir,
    setCounterAwal,
    setJumlahNG,
    setJumlahBarang,
    setKeterangan,
    mesinOperator,
    isiPerKemasan,
    aktualCavity,
    counterAkhir,
    counterAwal,
    jumlahNG,
    jumlahBarang,
    keterangan,
    isError,
    rejectDesc,
    popUpKirim,
    setDowntime,
    downtime,
    setPopUpKirim,
    subMenuOperator,
    setSubMenuOperator,
    listTopPlate,
    handleJumlahTopPlate,
    handleKirimTopPlate,
    dateOperator,
    isToco,
    setIsToco,
    sisaShiftSebelumnya,
    setSisaShiftSebelumnya,
    produkVP,
    setProdukVP,
    shiftPasangKaret,
    setShiftPasangKaret,
    mesinPasangKaret,
    setMesinPasangKaret,
    keteranganPasangKaret,
    setKeteranganPasangKaret,
    handleKirimPasangKaret,
    jumlahPasangKaret,
    setJumlahPasangKaret,
    isErrorPasangKaret,
    filterBulan,
    setDowntimePasangKaret,
    downtimePasangKaret,
    kepalaShift,
    setKepalaShift,
    warnaVP,
    setWarnaVP,
    aktualCavityPasangKaret,
    setAktualCavityPasangKaret,
    setCustomerVentPlug,
    customerVentPlug,
    keteranganSementara,
    setKeteranganSementara,
    handleSaveKeterangan,
  } = useOperator();
  const checkCounterAkhir = counterAkhir ? counterAkhir : 0;
  const checkCounterAwal = counterAwal ? counterAwal : 0;
  return (
    <div className="container_inventory">
      {popUpKirim ? (
        <PopUpComponent>
          <p>Data Kamu Berhasil dikrim!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpKirim(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">Laporan Produksi</p>
      <div className="wrapper_sub_menu_history">
        <p
          className={
            subMenuOperator === "laporanProduksi"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuOperator("laporanProduksi")}
        >
          Produksi
        </p>
        {/* <p
          className={
            subMenuOperator === "catatan"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuOperator("catatan")}
        >
          Catatan
        </p> */}
        <p
          className={
            subMenuOperator === "laporanPasangKaret"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuOperator("laporanPasangKaret")}
        >
          Pasang Karet
        </p>
        <p
          className={
            subMenuOperator === "laporanTopPlate"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuOperator("laporanTopPlate")}
        >
          TopPlate
        </p>
      </div>
      {isError ? (
        <p style={{ color: "red", fontWeight: "bold" }}>
          Mohon Periksa Kolom yang Wajib Diisi!
        </p>
      ) : (
        ""
      )}
      {subMenuOperator === "laporanProduksi" ? (
        <>
          <p style={{ fontSize: "12px", color: "red" }}>
            <b style={{ fontSize: "14px" }}>!</b> Fitur catatan sementara ada di
            halaman login, jika ingin menggunakannya bisa save catatan tanpa
            harus login.
          </p>
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "24%" }}>
              <p>
                Pilih Mesin<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                value={mesinOperator}
                onChange={(e) => setMesinOperator(e.target.value)}
              >
                <option disabled>Pilih Mesin</option>

                {constantNoMesin &&
                  constantNoMesin.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
              </select>
            </div>
            <div style={{ maxWidth: "35%" }}>
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                value={dateOperator}
                style={{ height: "19px" }}
                onChange={(e) => setDateOperator(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: "35%" }}>
              <p>
                Pilih Shift<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                onChange={(e) => setShiftOperator(e.target.value)}
              >
                <option selected={shiftOperator === "Pilih Shift"} disabled>
                  Pilih Shift
                </option>
                <option value="1">Shift 1</option>
                <option value="2">Shift 2</option>
                <option value="3">Shift 3</option>
              </select>
            </div>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>
                Isi Per Kemasan<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Isi Per Kemasan"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={isiPerKemasan}
                onChange={(e) =>
                  setIsiPerKemasan(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div>
              <p>
                Aktual Cavity<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Aktual Cavity"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={aktualCavity}
                onChange={(e) => setAktualCavity(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>
                Counter Akhir<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Counter Akhir"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={counterAkhir}
                onChange={(e) =>
                  setCounterAkhir(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div>
              <p>
                Counter Awal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Counter Awal"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={counterAwal}
                onChange={(e) =>
                  setCounterAwal(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>Selisih Counter</p>
              <p>{checkCounterAkhir - checkCounterAwal}</p>
            </div>
            <div>
              <p>
                Sisa Shift Sebelumnya<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Sisa Shift Sebelumnya"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={sisaShiftSebelumnya}
                onChange={(e) =>
                  setSisaShiftSebelumnya(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>
                Jumlah "NG"<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Jumlah NG"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={jumlahNG}
                onChange={(e) =>
                  setJumlahNG(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div>
              <p>
                Produk OK (Pcs)<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Produk OK"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={jumlahBarang}
                onChange={(e) =>
                  setJumlahBarang(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>
                Downtime (Menit)<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Downtime"
                type="number"
                style={{ maxWidth: "90%", height: "24px" }}
                value={downtime}
                onChange={(e) => setDowntime(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div
              style={{
                marginRight: "8px",
              }}
            >
              <p>
                Top Cover GTZ5S (#13/
                <br />
                #15)
              </p>
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => (isToco ? setIsToco(false) : setIsToco(true))}
              >
                <input
                  type="checkbox"
                  style={{ width: "16px", height: "16px", margin: "0 6px 0 0" }}
                  checked={isToco}
                />
                <label
                  style={{
                    fontSize: "14px",
                  }}
                >
                  TOP COVER
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: "13px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ width: "65%" }}>Perincian "NG"</p>
            <p style={{ width: "25%" }}>Jumlah</p>
          </div>
          {dataReject &&
            dataReject.map((data, index) => {
              return (
                <div
                  style={{
                    fontSize: "13px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <select
                    onChange={(e) => handleRejectDesc(index, e)}
                    name="id"
                    style={{ height: "32px", width: "65%" }}
                  >
                    <option selected={data.id === "Pilih Alasan"} disabled>
                      Pilih Alasan
                    </option>
                    {rejectDesc &&
                      rejectDesc.map((obj) => (
                        <option value={obj.desc}>{obj.desc}</option>
                      ))}
                  </select>
                  <input
                    placeholder="Jumlah"
                    type="number"
                    style={{ width: "25%" }}
                    name="totalNG"
                    value={Number(data.totalNG) ? Number(data.totalNG) : ""}
                    onChange={(e) => handleRejectDesc(index, e)}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              );
            })}

          <div
            className="container_button_tambah"
            style={{ justifyContent: "end", marginTop: "24px" }}
          >
            <div>
              <button
                className="button_plus"
                style={{ marginRight: "16px" }}
                onClick={() => removeFields()}
              >
                &#8722;
              </button>
              <button className="button_plus" onClick={() => addFields()}>
                &#43;
              </button>
            </div>
          </div>

          <div style={{ fontSize: "13px" }}>
            <p>Keterangan</p>
            <textarea
              style={{
                width: "98%",
                resize: "none",
                height: "84px",
                fontFamily: "arial",
              }}
              value={keterangan}
              onChange={(e) => setKeterangan(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="button_submit_memo"
              style={{
                marginRight: "24px",
                color: "black",
                border: "1px solid black",
                background: "white",
              }}
              onClick={() =>
                navigator.clipboard.readText().then(
                  (cliptext) => setKeterangan(cliptext),
                  (err) => console.log(err)
                )
              }
            >
              Tempel
            </button>
            <button
              className="button_submit_memo"
              onClick={() => handleKirim()}
            >
              Kirim
            </button>
          </div>
        </>
      ) : subMenuOperator === "laporanPasangKaret" ? (
        <div>
          {isErrorPasangKaret ? (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Mohon Periksa Kolom yang Wajib Diisi!
            </p>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "24%" }}>
              <p>
                Pilih Mesin<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                value={mesinPasangKaret}
                onChange={(e) => setMesinPasangKaret(e.target.value)}
              >
                <option disabled>Pilih Mesin</option>

                {constantNoMesin &&
                  constantNoMesin.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
              </select>
            </div>
            <div style={{ maxWidth: "35%" }}>
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                value={dateOperator}
                style={{ height: "19px" }}
                onChange={(e) => setDateOperator(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: "35%" }}>
              <p>
                Pilih Shift<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                onChange={(e) => setShiftPasangKaret(e.target.value)}
              >
                <option selected={shiftPasangKaret === "Pilih Shift"} disabled>
                  Pilih Shift
                </option>
                <option value="1">Shift 1</option>
                <option value="2">Shift 2</option>
                <option value="3">Shift 3</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "65%" }}>
              <p>
                Pilih Produk<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                value={produkVP}
                onChange={(e) => setProdukVP(e.target.value)}
              >
                <option selected={produkVP === "Pilih Produk"}>
                  Pilih Produk
                </option>
                <option value="VENT PLUG G18-A50 NEM (1)">
                  VENT PLUG G18-A50 NEM (1)
                </option>
                <option value="VENT PLUG G18-A50 NEM (2)">
                  VENT PLUG G18-A50 NEM (2)
                </option>
                <option value="VENT PLUG G18-A50 NL (1)">
                  VENT PLUG G18-A50 NL (1)
                </option>
                <option value="VENT PLUG G18-A50 NL (3)">
                  VENT PLUG G18-A50 NL (3)
                </option>
                <option value="VENT PLUG G18-A50 NL (4)">
                  VENT PLUG G18-A50 NL (4)
                </option>
                <option value="VENT PLUG G18-A50 NL (5)">
                  VENT PLUG G18-A50 NL (5)
                </option>
                <option value="VENT PLUG G30-A9 NEM">
                  VENT PLUG G30-A9 NEM
                </option>
                <option value="VENT PLUG G30-A9 NL">VENT PLUG G30-A9 NL</option>
                <option value="VENT PLUG F20-2K (1)">
                  VENT PLUG F20-2K (1)
                </option>
                <option value="VENT PLUG F20-2K (2)">
                  VENT PLUG F20-2K (2)
                </option>
                <option value="VENT PLUG F20-2K (4)">
                  VENT PLUG F20-2K (4)
                </option>
                <option value="VENT PLUG F20-2K (5)">
                  VENT PLUG F20-2K (5)
                </option>
              </select>
            </div>
            <div style={{ maxWidth: "35%" }}>
              <p>
                Pilih Warna<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                value={warnaVP}
                onChange={(e) => setWarnaVP(e.target.value)}
              >
                <option selected={warnaVP === "Pilih Warna"}>
                  Pilih Warna
                </option>
                <option value="HTM">HITAM</option>
                <option value="KNG">KUNING</option>
                <option value="ORG">ORANGE</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "35%" }}>
              <p>
                Customer<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                value={customerVentPlug}
                onChange={(e) => setCustomerVentPlug(e.target.value)}
              >
                <option selected={customerVentPlug === "Pilih Customer"}>
                  Pilih Customer
                </option>
                <option value="GS">GS</option>
                <option value="CBI">CBI</option>
                <option value="YBI">YBI</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "65%" }}>
              <p>
                Kepala Shift<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "24px", width: "100%" }}
                value={kepalaShift}
                onChange={(e) => setKepalaShift(e.target.value)}
              >
                <option disabled checked={kepalaShift === "Pilih Kepala Shift"}>
                  Pilih Kepala Shift
                </option>
                <option value={"SUPRIADI"}>SUPRIADI</option>
                <option value={"ACHMAD SANIN"}>ACHMAD SANIN</option>
                <option value={"SARIPUDIN"}>SARIPUDIN</option>
              </select>
            </div>
            <div style={{ width: "45%" }}>
              <p>
                Aktual Cavity<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Aktual Cavity"
                type="number"
                style={{ height: "24px" }}
                value={aktualCavityPasangKaret}
                onChange={(e) =>
                  setAktualCavityPasangKaret(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "65%", marginRight: "24px" }}>
              <p>
                Jumlah Pasang Karet (Pcs)
                <label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Jumlah Pasang Karet (Pcs)"
                type="number"
                style={{ height: "24px", width: "100%" }}
                value={jumlahPasangKaret}
                onChange={(e) =>
                  setJumlahPasangKaret(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div style={{ width: "45%" }}>
              <p>Downtime (Menit)</p>
              <input
                placeholder="Downtime"
                type="number"
                style={{ height: "24px" }}
                value={downtimePasangKaret}
                onChange={(e) =>
                  setDowntimePasangKaret(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div style={{ fontSize: "13px" }}>
            <p>Keterangan Pasang Karet</p>
            <textarea
              style={{
                width: "98%",
                resize: "none",
                height: "84px",
                fontFamily: "arial",
              }}
              value={keteranganPasangKaret}
              onChange={(e) => setKeteranganPasangKaret(e.target.value)}
            />
          </div>
          <br />
          <button
            className="button_submit_memo"
            style={{ margin: "0" }}
            onClick={() => handleKirimPasangKaret()}
          >
            Kirim
          </button>
        </div>
      ) : subMenuOperator === "catatan" ? (
        <div>
          <p>
            <b>
              Ini adalah catatan sementara, mohon di copy ke laporan operator
              jika ingin menulis laporan!
            </b>
          </p>
          <textarea
            style={{
              width: "98%",
              resize: "none",
              height: "84px",
              fontFamily: "arial",
            }}
            value={keteranganSementara}
            onChange={(e) => setKeteranganSementara(e.target.value)}
          />
          <br />
          <br />
          <div>
            <button
              className="button_submit_memo"
              style={{ margin: "0" }}
              onClick={() => handleSaveKeterangan()}
            >
              Save
            </button>
            <button
              className="button_submit_memo"
              style={{
                margin: "0 24px 0 0",
                color: "black",
                border: "1px solid black",
                background: "white",
              }}
              onClick={() => navigator.clipboard.writeText(keteranganSementara)}
            >
              Copy
            </button>
          </div>
        </div>
      ) : (
        <>
          {listTopPlate &&
            listTopPlate.map((obj, index) => {
              return (
                <div
                  style={{
                    background: "white",
                    padding: "0 12px 12px 12px",
                    marginBottom: "18px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "24px", fontWeight: "bold" }}>
                      Mesin {obj.mesin}
                    </p>
                    <p style={{ fontWeight: "bold" }}>{obj.customer}</p>
                  </div>
                  <p>{obj.moldType}</p>
                  <input
                    type="number"
                    name="jumlah"
                    className="input_jumlah"
                    style={{ margin: "12px 0 6px 0" }}
                    placeholder="Masukkan Jumlah Top Plate (Pcs)"
                    onChange={(e) => handleJumlahTopPlate(index, e)}
                  />
                </div>
              );
            })}
          {listTopPlate && listTopPlate.length > 0 ? (
            <button
              className="button_submit_memo"
              style={{ margin: "0" }}
              onClick={() => handleKirimTopPlate()}
            >
              Kirim
            </button>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
export default Operator;
