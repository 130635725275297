import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { formatDate } from "../../util/formatDate";
import moment from "moment";

export const useStockIn = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataListBahan, setDataListBahan] = useState();
  const [dataListCrusher, setDataListCrusher] = useState();
  const [dataListPewarna, setDataListPewarna] = useState();
  const [dataLogBahanCrusher, setDataLogBahanCrusher] = useState();
  const [companyRequest, setCompanyRequest] = useState("");
  const [materialBahan, setMaterialBahan] = useState("BAKU");
  const [materialName, setMaterialName] = useState("");
  const [jumlahRequest, setJumlahRequest] = useState("");
  const [keteranganRequestStockIn, setKeteranganRequestStockIn] = useState("");
  const [popUpRequest, setPopUpRequest] = useState(false);
  const [popUpErrorRequest, setPopUpErrorRequest] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const [statusBahan, setStatusBahan] = useState("Keluar");
  const [subMenuStockIn, setSubMenuStockIn] = useState("BahanBaku");
  const [filterDate, setFilterDate] = useState(formatDate);

  useEffect(() => {
    if (dataUser.role === "warehouse_crusher") {
      setSubMenuStockIn("Crusher");
    }
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBahan`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListBahan,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListCrusher`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListCrusher,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListPewarna`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListPewarna,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getLogBahanCrusher`,
      { tim: dataUser.tim, month: "12", date: filterDate },
      dataUser.auth,
      setDataLogBahanCrusher,
      ""
    );
    setIsReload(false);
  }, [isReload, filterDate]);

  const validateNamaBahan = () => {
    let data;
    dataListBahan &&
      dataListBahan.forEach((obj) => {
        if (obj.company === companyRequest) {
          if (materialBahan === "PENDUKUNG") {
            data = obj.bahanPendukung;
          } else if (materialBahan === "BAKU") {
            data = obj.bahanBaku;
          }
        }
      });
    return data;
  };
  const validateNamaCrusher = () => {
    let data;
    dataListCrusher &&
      dataListCrusher.forEach((obj) => {
        if (obj.company === companyRequest) {
          data = obj.data;
        }
      });
    return data;
  };
  const validateNamaPewarna = () => {
    let data;
    dataListPewarna &&
      dataListPewarna.forEach((obj) => {
        if (obj.company === companyRequest) {
          data = obj.data;
        }
      });
    return data;
  };

  useEffect(() => {
    validateNamaBahan();
  }, [companyRequest]);

  const dataBahan = validateNamaBahan();
  const dataCrusher = validateNamaCrusher();
  const dataPewarna = validateNamaPewarna();

  const handleSubmitRequest = (button) => {
    if (button === "OK") {
      setPopUpRequest(false);
      setPopUpErrorRequest(false);
    }
  };

  const handleKirimRequestIn = async () => {
    if (
      companyRequest !== "" &&
      materialBahan !== "" &&
      materialName !== "" &&
      jumlahRequest !== ""
    ) {
      setPopUpRequest(true);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/stockIn`,
        {
          company: companyRequest,
          material: materialName,
          jenisBahan: materialBahan,
          total: Number(jumlahRequest),
          keterangan: keteranganRequestStockIn,
          updatedBy: dataUser.name,
          statusBahan: statusBahan,
          tim: dataUser.tim,
          type: subMenuStockIn,
          updateTime: moment().utcOffset("+0700").format(),
          month: moment().utcOffset("+0700").month() + 1,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsReload(true);
      setCompanyRequest("");
      setMaterialName("");
      setJumlahRequest("");
      setKeteranganRequestStockIn("");
      setStatusBahan("Keluar");
    } else {
      setPopUpErrorRequest(true);
    }
  };

  return {
    dataUser,
    companyRequest,
    setCompanyRequest,
    setMaterialBahan,
    setMaterialName,
    handleSubmitRequest,
    dataBahan,
    popUpRequest,
    materialBahan,
    setPopUpRequest,
    jumlahRequest,
    setJumlahRequest,
    setKeteranganRequestStockIn,
    keteranganRequestStockIn,
    dataListBahan,
    handleKirimRequestIn,
    materialName,
    popUpErrorRequest,
    statusBahan,
    setStatusBahan,
    subMenuStockIn,
    setSubMenuStockIn,
    dataCrusher,
    dataLogBahanCrusher,
    filterDate,
    setFilterDate,
    dataPewarna,
  };
};
