import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import moment from "moment";

export const useInputBarangJadi = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataListBarangJadi, setDataListBarangJadi] = useState();
  const [companyPickInputGbj, setCompanyPickInputGbj] = useState("");
  const [jumlahInputGbj, setJumlahInputGbj] = useState("");
  const [keteranganGbj, setKeteranganGbj] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [produkPick, setProdukPick] = useState("");
  const [subMenuInputGbj, setSubMenuInputGbj] = useState("BahanJadi");

  const [popUpSubmit, setPopUpSubmit] = useState(false);
  const [popUpErrorSubmit, setPopUpErrorSubmit] = useState(false);
  const [popUpSearch, setPopUpSearch] = useState(false);

  const [dataHistorySearch, setDataHistorySearch] = useState();
  const [dataPickHistory, setDataPickHistory] = useState("");
  const [bulanPickHistory, setBulanPickHistory] = useState(
    moment().utcOffset("+0700").month() + 1
  );
  const [tahunPickHistory, setTahunPickHistory] = useState(
    moment().utcOffset("+0700").year()
  );

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBarangJadi`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListBarangJadi,
      ""
    );
    setIsReload(false);
  }, [isReload]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/searchHistoryInventory`,
      {
        tim: dataUser.tim,
        dataSearch: dataPickHistory,
        company: companyPickInputGbj,
        updateTime: tahunPickHistory + "-" + bulanPickHistory,
      },
      dataUser.auth,
      setDataHistorySearch,
      ""
    );
  }, [popUpSearch, bulanPickHistory, tahunPickHistory]);

  const validateBarangJadi = () => {
    let data;
    dataListBarangJadi &&
      dataListBarangJadi.forEach((obj) => {
        if (obj.company === companyPickInputGbj) {
          data = obj.data;
        }
      });
    return data;
  };
  const dataBarangJadi = validateBarangJadi();

  let reFormat = [];
  dataBarangJadi &&
    dataBarangJadi.forEach((element) => {
      reFormat.push({ value: element.jenis, label: element.material });
    });

  const handleKirimInputGbj = async () => {
    if (
      companyPickInputGbj !== "" &&
      jumlahInputGbj !== "" &&
      produkPick !== ""
    ) {
      setPopUpSubmit(true);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addGudangBarangJadi`,
        {
          company: companyPickInputGbj,
          total: Number(jumlahInputGbj),
          keterangan: keteranganGbj,
          material: produkPick.label,
          updatedBy: dataUser.name,
          tim: dataUser.tim,
          type: produkPick.value,
          updateTime: moment().utcOffset("+0700").format(),
          month: moment().utcOffset("+0700").month() + 1,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsReload(true);
      setCompanyPickInputGbj("");
      setJumlahInputGbj("");
      setKeteranganGbj("");
      setSubMenuInputGbj("BahanJadi");
      setProdukPick("");
    } else {
      setPopUpErrorSubmit(true);
    }
  };

  return {
    dataUser,
    popUpSubmit,
    popUpErrorSubmit,
    setCompanyPickInputGbj,
    setJumlahInputGbj,
    jumlahInputGbj,
    keteranganGbj,
    setKeteranganGbj,
    companyPickInputGbj,
    dataListBarangJadi,
    reFormat,
    setProdukPick,
    produkPick,
    handleKirimInputGbj,
    setPopUpSubmit,
    setPopUpErrorSubmit,
  };
};
