import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { today } from "../../util/formatDate";

import moment from "moment";
import * as XLSX from "xlsx";

export const useInventory = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [companyPick, setCompanyPick] = useState("GS");
  const [dataInventory, setDataInventory] = useState();
  const [dataListCrusher, setDataListCrusher] = useState();
  const [dataListPewarna, setDataListPewarna] = useState();
  const [dataBarangJadi, setDataBarangJadi] = useState();
  const [popUpKirimPo, setPopUpKirimPo] = useState(false);
  const [subMenu, setSubMenu] = useState("bahanBaku");
  const [dataKeluar, setDataKeluar] = useState();
  const [keteranganKirim, setKeteranganKirim] = useState("");
  const [jumlahKirim, setJumlahKirim] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const [tanggalKirim, setTanggalKirim] = useState(today);
  const [isRefresh, setIsRefresh] = useState(false);

  const [dataHistorySearch, setDataHistorySearch] = useState();
  const [popUpSearch, setPopUpSearch] = useState(false);
  const [dataPickHistory, setDataPickHistory] = useState();
  const [bulanPickHistory, setBulanPickHistory] = useState(
    moment().utcOffset("+0700").month() + 1
  );
  const [tahunPickHistory, setTahunPickHistory] = useState(
    moment().utcOffset("+0700").year()
  );

  const [paramSearch, setParamSearch] = useState("material");
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/searchHistoryInventory`,
      {
        tim: dataUser.tim,
        dataSearch: dataPickHistory,
        company: companyPick,
        updateTime: tahunPickHistory + "-" + bulanPickHistory,
      },
      dataUser.auth,
      setDataHistorySearch,
      ""
    );
  }, [popUpSearch, bulanPickHistory, tahunPickHistory]);

  useEffect(() => {
    if (dataUser.role === "warehouse_gbj_admin") {
      setSubMenu("barangJadi");
    }
  }, []);

  useEffect(() => {
    if (subMenu === "bahanBaku") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getInventory`,
        { tim: dataUser.tim, company: companyPick },
        dataUser.auth,
        setDataInventory,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListCrusher`,
        { tim: dataUser.tim },
        dataUser.auth,
        setDataListCrusher,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListPewarna`,
        { tim: dataUser.tim },
        dataUser.auth,
        setDataListPewarna,
        ""
      );
      setIsRefresh(false);
    } else if (subMenu === "barangJadi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListBarangJadi`,
        { tim: dataUser.tim },
        dataUser.auth,
        setDataBarangJadi,
        ""
      );
      setIsRefresh(false);
    }
  }, [companyPick, subMenu, isRefresh]);

  const searchBarangJadi =
    dataBarangJadi &&
    dataBarangJadi.find((e) => (e.company == companyPick ? e : 0));

  const handleExportBarangJadi = () => {
    const filteredDataExcel =
      searchBarangJadi &&
      searchBarangJadi.data.map((obj, index) => {
        return {
          No: index + 1,
          "NAMA PRODUK": obj.material,
          "JUMLAH (PCS)": obj.weight,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [{ wch: 4 }, { wch: 50 }, { wch: 15 }];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Barang Jadi");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Barang Jadi PT.${companyPick} ${
        moment().utcOffset("+0700").month() + 1
      }-${moment().utcOffset("+0700").year()}.xlsx`
    );
  };

  const dataPick = () => {
    let data;
    dataInventory &&
      dataInventory.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };
  const Material = dataPick();

  const dataPickCrusher = () => {
    let data;
    dataListCrusher &&
      dataListCrusher.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };
  const Crusher = dataPickCrusher();

  const dataPickPewarna = () => {
    let data;
    dataListPewarna &&
      dataListPewarna.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };
  const Pewarna = dataPickPewarna();

  const handlePickItem = (data) => {
    setDataKeluar(data);
    setPopUpKirimPo(true);
  };

  const handleKirimPo = () => {
    if (keteranganKirim !== "" && jumlahKirim !== "") {
      setErrMsg(false);
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/kirimBarang`,
        {
          tim: dataUser.tim,
          company: companyPick,
          keterangan: keteranganKirim,
          total: Number(jumlahKirim),
          dateExit: tanggalKirim,
          updatedBy: dataUser.name,
          material: dataKeluar.material,
          jenis: dataKeluar.jenis,
          updateTime: moment().utcOffset("+0700").format(),
          month: moment().utcOffset("+0700").month() + 1,
        },
        dataUser.auth,
        "",
        ""
      );

      setIsRefresh(true);
      setPopUpKirimPo(false);
      setKeteranganKirim("");
      setJumlahKirim("");
    } else {
      setErrMsg(true);
    }
  };

  const searchFeature = (params) => {
    if (paramSearch === "material" && valueSearch !== "") {
      let searchName = [];
      searchBarangJadi &&
        searchBarangJadi.data.forEach((value) => {
          if (value.material.toLowerCase().includes(params.toLowerCase())) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return searchBarangJadi && searchBarangJadi.data;
    }
  };
  let dataTemp = searchFeature(valueSearch);

  const handlePick = (data) => {
    setPopUpSearch(true);
    setDataPickHistory(data);
  };

  return {
    Material,
    setCompanyPick,
    companyPick,
    subMenu,
    setSubMenu,
    dataUser,
    handlePickItem,
    popUpKirimPo,
    setPopUpKirimPo,
    dataKeluar,
    keteranganKirim,
    setKeteranganKirim,
    jumlahKirim,
    setJumlahKirim,
    handleKirimPo,
    errMsg,
    setTanggalKirim,
    tanggalKirim,
    Crusher,
    popUpSearch,
    setPopUpSearch,
    setDataPickHistory,
    tahunPickHistory,
    setTahunPickHistory,
    bulanPickHistory,
    setBulanPickHistory,
    dataHistorySearch,
    Pewarna,
    setValueSearch,
    dataTemp,
    handlePick,
    valueSearch,
    handleExportBarangJadi,
  };
};
