import React from "react";
import CloseLogo from "../../Assets/close_icon.png";
import "./PatrolQc.css";
import PopUpComponent from "../../components/PopUp/PopUpComponent.js";
import { usePatrolQc } from "./usePatrolQc.js";
import ScanLogo from "../../Assets/scan-logo.png";
import { changeFormatDate, getTime } from "../../util/formatDate.js";

const LaporanKerusakan = () => {
  const {
    popUp,
    setPopUp,
    ref,
    subMenu,
    setSubMenu,
    errMsg,
    handlePickLaporan,
    dataUser,
    dataQrMesin,
    mesinPick,
    setMesinPick,
    handleSubMenuHistoryPatrol,
    dataPatrol,
    shiftPatrol,
    setShiftPatrol,
    shiftHistoryPatrol,
    setShiftHistoryPatrol,
    datePatrolQc,
    setDatePatrolQc,
    mesinPatrol,
    setMesinPatrol,
    setTroubleDesc,
    troubleDesc,
  } = usePatrolQc();

  const sortData =
    dataQrMesin &&
    dataQrMesin.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_inventory">
      <p className="title_inventory">Patrol Checker</p>
      {dataUser.role === "super_user" ||
      dataUser.role === "qc_admin" ||
      dataUser.role === "qc_user" ||
      dataUser.role === "ppic_admin" ? (
        <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
          <p
            className={
              subMenu === "patrol"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("patrol")}
          >
            Patrol
          </p>
          <p
            className={
              subMenu === "history"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => handleSubMenuHistoryPatrol()}
          >
            History
          </p>
        </div>
      ) : (
        ""
      )}
      {popUp ? (
        <PopUpComponent>
          <img
            className="width_edit_logo"
            src={CloseLogo}
            style={{ float: "right" }}
            onClick={() => setPopUp(false)}
            alt="edit_logo"
          />
          {/* <QrReader
            ref={ref}
            delay={300}
            onError={handleError}
            onResult={handleScan}
            constraints={{
              facingMode: "environment",
            }}
          /> */}
          <p style={{ color: "red", fontWeight: "bold" }}>{errMsg}</p>
        </PopUpComponent>
      ) : (
        ""
      )}
      {subMenu === "patrol" ? (
        <>
          <div style={{ margin: "18px 0 18px 0" }} ref={ref} id="patrol"></div>
          <p style={{ color: "red", fontWeight: "bold" }}>{errMsg}</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <select
              className="input_select_memo_ppic"
              style={{ width: "40%" }}
              value={shiftPatrol}
              onChange={(e) => setShiftPatrol(e.target.value)}
            >
              <option selected={shiftPatrol === "Pilih Shift"} disabled>
                Pilih Shift
              </option>
              <option value="1">Shift 1</option>
              <option value="2">Shift 2</option>
              <option value="3">Shift 3</option>
            </select>
            <br />
            <select
              defaultValue="Pilih No Mesin"
              onChange={(e) => setMesinPick(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "40%" }}
              name="mesin"
              value={mesinPick}
            >
              <option selected={mesinPick === "Pilih Nomor Mesin"} disabled>
                Pilih Nomor Mesin
              </option>
              {dataQrMesin &&
                dataQrMesin.map((data) => (
                  <option value={data.id_mesin}>{data.mesin}</option>
                ))}
            </select>
            <img
              style={{ width: "42px", cursor: "pointer", margin: "0 6px" }}
              src={ScanLogo}
              onClick={() => handlePickLaporan()}
            />
          </div>
          <div style={{ marginTop: "32px" }}>
            <p>
              Keterangan{" "}
              <b>(Diisi Jika ada Trouble / Produk Masih Disetting)</b>
            </p>
            <textarea
              style={{
                resize: "none",
                height: "108px",
                width: "98%",
              }}
              value={troubleDesc}
              onChange={(e) => setTroubleDesc(e.target.value)}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <select
              className="input_select_memo_ppic"
              style={{ width: "96px" }}
              value={shiftHistoryPatrol}
              onChange={(e) => setShiftHistoryPatrol(e.target.value)}
            >
              <option selected={shiftHistoryPatrol === "Pilih Shift"} disabled>
                Pilih Shift
              </option>
              <option value="1">Shift 1</option>
              <option value="2">Shift 2</option>
              <option value="3">Shift 3</option>
            </select>
            <select
              className="input_select_memo_ppic"
              style={{ width: "96px" }}
              value={mesinPatrol}
              onChange={(e) => setMesinPatrol(e.target.value)}
            >
              <option selected={mesinPatrol === "Pilih Mesin"} disabled>
                Pilih Mesin
              </option>
              {sortData &&
                sortData.map((data) => {
                  return <option value={data.mesin}>{data.mesin}</option>;
                })}
            </select>
            <input
              type="date"
              className="date_pick_memo_ppic"
              style={{ margin: 0 }}
              value={datePatrolQc}
              onChange={(e) => setDatePatrolQc(e.target.value)}
            />
          </div>
          <div
            className="container_card_kerusakan"
            style={{ marginTop: "18px" }}
          >
            {dataPatrol &&
              dataPatrol.data.map((data) => {
                return (
                  <div
                    className="wrapper_card_kerusakan"
                    style={{
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: "6px 0" }}>Mesin {data.mesin}</p>
                      <p style={{ margin: "6px 0" }}>{data.name}</p>
                    </div>
                    <div>
                      <p style={{ margin: "6px 0", fontWeight: "bold" }}>
                        {data.moldType}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: "6px 0" }}>
                        {changeFormatDate(data.timestamp)}
                      </p>
                      <p style={{ margin: "6px 0" }}>
                        {getTime(data.timestamp)}
                      </p>
                    </div>
                    <div>
                      {data.troubleDesc ? (
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          Trouble
                        </p>
                      ) : (
                        ""
                      )}
                      {data.troubleDesc}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};
export default LaporanKerusakan;
